.mental-health-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
}
  
.mental-health-card {
    background-color: #EDE7F6;
    border: 1px solid #7D6E9B;
    margin-bottom: 30px;
    padding: 10px;
    width: 100%;
    max-width: 800px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
}

.card-title {
    background-color: #7D6E9B;
    color: white;
    padding: 10px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 24px;
}
  
.card-subtitle {
    color: #101010;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 30px;
}
  
.card-content {
    background-color: #D1C4E9;
    padding: 15px;
    border-radius: 8px;
    margin-top: -10px;
}
  
.card-image {
    width: 80%; /* Increased width */
    height: auto;
    margin-bottom: 15px;
}
  
.card-description {
    color: #030303;
    font-size: 20px;
    line-height: 1.5; /* Increase line height for better readability */
}

.content-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.text-section {
    flex: 1;
    padding: 10px;
}

.image-section {
    flex: 1;
    text-align: center;
}

@media (max-width: 600px) {
    .mental-health-container {
        padding: 20px;
        padding-top: 90px;
    }

    .mental-health-card {
        width: 90%;
    }

    .card-title {
        font-size: 20px;
    }

    .card-description {
        font-size: 20px;
    }

    .content-layout {
        flex-direction: column;
    }

    .text-section, .image-section {
        width: 100%;
        text-align: center;
    }

    .card-image {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .mental-health-container {
        padding: 20px;
        padding-top: 90px;
    }

    .mental-health-card {
        width: 90%;
    }

    .card-title {
        font-size: 20px;
    }

    .card-description {
        font-size: 20px;
    }
}
