.back-button {
    padding: 15px 35px;
    margin: 10px;
    background-color: #7D6E9B;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  