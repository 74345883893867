/* Header.css */
header {
  background-color: #ffffff; 
}

.navbar {
  display: flex;
  flex-direction: row; /* Default to row for larger screens */
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 px; /* Adjusted for consistent spacing */

}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 50%; /* Ensure logo is responsive */
  height: auto;
  object-fit: contain;
}

.navbar-links {
  display: flex;
  justify-content: space-between; /* This will place the logo and navbar-links at opposite ends */
  align-items: center;
  gap: 40px;
  padding-right: 350px; /* Adjusted padding for overall navbar padding */
  flex-wrap: wrap; /* Allows items to wrap in smaller screens */
}

.navbar-links a {
  background-color: #EFEFEF;
  border: 1px solid #D1D1D1;
  border-radius: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px 20px; /* Standardized padding */
  transition: all 0.3s ease;
  color: black; /* Text color changed to black */
  font-weight: bold; /* Text weight set to bold */
}

.navbar-links a:hover {
  color: #8F8ECA;
}

.tag {
  text-align: center;
  width: 100%;
  color: #ffffff;
  background-color: #7D6E9B;
  font-size: 40px;
  font-family: 'Handwriting', sans-serif;
  padding: 10px 0;
}

.hamburger {
  display: none;
}

.cancel-button {
  display: none; /* Hide cancel button on larger screens by default */
}

@media (max-width: 600px) {
  .hamburger {
    display: block;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 2; 
    font-size: 30px; 
    cursor: pointer;
    color: #8F8ECA;
  }

  .logo {
    justify-content: center; /* Center logo container */
  }

  .logo img {
    width: 80%; /* Adjust size as needed */
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: 75px; /* Adjust based on the height of your header */
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #fff; /* Or any other color */
    text-align: center;
  }

  .navbar-links.open{
    display: flex;
    overflow-y: auto; 
    max-height: 80vh; /* Set a maximum height */
    z-index: 9999; /* Ensure the menu appears above all other content */
    padding-bottom: 40px;
    padding-right: 150px;
    padding-left: 150px;
    position: absolute;
  }
  .cancel-button {
    display: block;
  }
  

  .navbar-links a {
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Optional divider */
    width: 100%;
  }

  .tag {
    font-size: 15px;
    padding: 30px;
    position: absolute;
    width: 100%;
    top: 180px; /* Adjust this value to position the tag correctly */
    padding-bottom: 40px;
    padding-left: 2px;
  }
}

@media (max-width: 900px) {
  .hamburger {
    display: block;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 2; 
    font-size: 30px; 
    cursor: pointer;
    color: #8F8ECA;
  }
  .logo {
    justify-content: center; /* Center logo container */
  }

  .logo img {
    width: 80%; /* Adjust size as needed */
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: 75px; /* Adjust based on the height of your header */
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #fff; /* Or any other color */
    text-align: center;
  }

  .navbar-links.open{
    display: flex;
    overflow-y: auto; 
    max-height: 80vh; /* Set a maximum height */
    z-index: 9999; /* Ensure the menu appears above all other content */
    padding-bottom: 40px;
    padding-right: 150px;
    padding-left: 150px;
    position: absolute;
  }
  .cancel-button {
    display: block;
  }
  

  .navbar-links a {
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Optional divider */
    width: 100%;
  }

  .tag {
    font-size: 15px;
    padding: 30px;
    position: absolute;
    width: 100%;
    top: 180px; 
    padding-bottom: 40px;
    padding-left: 2px;
  }
}