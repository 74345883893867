/* HomePage.css */
main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 20px; /* Added padding for better spacing */
}

main h1 {
  font-size: 2rem;
  color: #333;
  text-align: center; /* Center align text */
}

@media (max-width: 600px) {
  main h1 {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
  }
}
