/* OurTeam.css */
.team-page-container {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 50px;
  background-color: rgb(117, 169, 154);
}

.team-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(241, 244, 243);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(1, 0, 0, 0.1);
  width: 500px;
  height: auto;
  max-width: 100%; /* Ensure the card does not exceed the screen width */
  overflow: hidden; /* Prevent content overflow */
}

.team-avatar {
  width: 150px; /* Fixed width */
  height: 150px; /* Fixed height */
  object-fit: cover; 
  margin-bottom: 20px;
  border-radius: 50%;
  background-color: #FFF; 
}

.team-info {
  text-align: center;
  width: 100%;
}

.team-title {
  font-size: 1.5rem;
  color: #030303;
  margin-bottom: 10px;
  margin-top: 0;
}

.team-description {
  font-size: 1rem;
  color: #333;
  padding: 0 10px;
}

.bio-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.bio-modal-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  width: auto;
  max-width: 500px;
  text-align: center;
}

.bio-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.see-bio-button {
  background: none;
  border: none;
  color: #0000EE; 
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  display: inline; 
}

@media (max-width: 600px) {
  .team-container {
    flex-direction: column;
    padding: 20px;
    padding-top: 60px;
  }

  .team-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .team-avatar {
    width: 150px; 
    height: 150px; 
    object-fit: cover; 
    border-radius: 50%; 
    margin-bottom: 20px;
  }
  
  .back-button {
    align-self: center;
  }
}
