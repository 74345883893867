.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  width: 100%;
  margin-bottom: 10px;
}

.video-background-container {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
}

.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.waitlist-button-container {
  text-align: center;
  padding: 20px;
  width: 100%;
  height: auto;
  background-color: rgb(188, 240, 227);
}

.waitlist-button {
  padding: 20px 50px;
  font-size: 20px;
  color: rgb(8, 8, 8);
  background-color: rgb(117, 169, 154);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 0px;
}

.waitlist-button:hover {
  background-color: #7D6E9B;
}

.waitlist-container-text {
  font-size: 20px;
  font-weight: normal;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  padding: 2rem;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: linear-gradient(45deg, #7d7bb8, rgb(155, 210, 196));
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 500px;
  width: 90%;
  max-height: 80vh; /* Set a max-height to make the modal content scrollable */
  overflow-y: auto; /* Enable vertical scrolling */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
}

.waitlist-header h3 {
  color: #172020;
  margin-bottom: 1rem;
}

.waitlist-header p {
  color: #070b0b;
  margin-bottom: 1.5rem;
}

.waitlist-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  margin-bottom: 0.5rem;
  color: #070b0b;
}

.waitlist-form input[type="email"],
.waitlist-form select,
.waitlist-form textarea {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.waitlist-form textarea {
  height: 60px;
  resize: vertical; /* Allow resizing of textareas */
}

.waitlist-submit-button {
  padding: 10px 20px;
  margin-top: 1rem;
  cursor: pointer;
}

.waitlist-confirmation-message {
  color: #fff;
  background-color: #85e2e2;
  padding: 10px;
  border-radius: 8px;
}

.consent-checkbox {
  display: flex;
  align-items: center;
  margin-top: 1rem; /* Ensure spacing between fields */
}

.consent-checkbox input[type="checkbox"] {
  margin-right: 10px;
}

@media (max-width: 600px) {
  .main-container {
    padding-top: 50px;
  }

  .video-background-container {
    height: 45vh;
  }

  .waitlist-button {
    padding: 10px 30px;
    font-size: 16px;
  }

  .waitlist-container-text {
    font-size: 16px;
    padding: 1rem;
  }

  .modal-content {
    width: 90%;
  }
}
