/* contact.css */
.contact-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-direction: column; 
  min-height: 100vh; 
  background-color: #7D6E9B;
  padding: 20px;
  color: #ffffff; 
}

.contact-info {
  background-color: #ffffff;
  color: #000; 
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  text-align: center; 
  max-width: 600px; 
  width: 100%; 
}

.social-media {
  display: flex;
  justify-content: center; 
  gap: 20px; 
}

.social-icon {
  width: 40px;
  height: 40px;
  fill: #7D6E9B; 
}

header:after {
  content: '';
  display: block;
  height: 10px; 
  background-color: #ffffff; 
  width: 100%; 
}

@media (max-width: 600px) {
  header:after {
      background-color: #ffffff; 
  }
  
  .contact-container {
      padding: 50px;
  }
}
